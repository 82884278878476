import React, { Component } from 'react';
import './App.css';
// import Separator from './Separator'
// import MyNavbar from './Navbar'
// import Section1 from './Section'
// import Mugen from './Mugen'
// import Content from './Content'
// import Roadmap from './Roadmap'
// import Team from './Team'
// import Footer from './Footer'



// class App extends Component {

//   async componentWillMount() {
//     //await this.loadWeb3()
//     //await this.loadBlockchainData()
//   }

//   constructor(props) {
//     super(props)
//     this.state = {
//       //hellowWorld: 'hellowWorld a',
//     }

//     //this.createPost = this.createPost.bind(this)
//   }

//   render() {
//     return (
//       <div>
//         <MyNavbar />
//         <Section1 />
//         <Mugen />
//         <Separator myRef="features"/>
//         <Content />
//         <Separator myRef="roadmap"/>
//         <Roadmap />
//         <Separator myRef="team"/>
//         <Team />
//         <Footer />
//       </div>
//     );
//   }
// }



function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Project on hold for a long time....</h1>
      </header>
    </div>
  );
}

export default App;
